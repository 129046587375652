import Notify from 'bnc-notify'

import { init } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import trezorModule from '@web3-onboard/trezor'
import ledgerModule from '@web3-onboard/ledger'
import walletConnectModule from '@web3-onboard/walletconnect'
import coinbaseModule from '@web3-onboard/coinbase'
import portisModule from '@web3-onboard/portis'
import magicModule from '@web3-onboard/magic'
import fortmaticModule from '@web3-onboard/fortmatic'
import torusModule from '@web3-onboard/torus'
import keepkeyModule from '@web3-onboard/keepkey'
import gnosisModule from '@web3-onboard/gnosis'
import newLogo from './images/Logo Vector.png'

// Replace with your DApp's Infura ID
const INFURA_ID = '80e9ad06bfe9427a85177cf15d057ff5'

const networkId = 4
const apiUrl = process.env.REACT_APP_API_URL
//created on blocknative dashboard
const dappId = 'a25f54ca-bc70-4b15-99f1-b73d7960ad9f'

const injected = injectedModule()
const coinbase = coinbaseModule()
const walletConnect = walletConnectModule()

const portis = portisModule({
  apiKey: 'b2b7586f-2b1e-4c30-a7fb-c2d1533b153b'
})

const fortmatic = fortmaticModule({
  apiKey: 'pk_test_CF8250DDEEDA61B8'
})

const torus = torusModule({
  clientId: 'BA3OZ5o8Z5_qSYlnrH7WyqD9VpoaV_HqTcL_US7q_4JhX3LaXEiXSdaznavi81oNG98hYXYYrG-eTeQKb8GjWd4'
})
const ledger = ledgerModule()
const keepkey = keepkeyModule()

const gnosis = gnosisModule()

const trezorOptions = {
  email: 'test@test.com',
  appUrl: 'https://www.blocknative.com'
}

const trezor = trezorModule(trezorOptions)

const magic = magicModule({
  // Example api key, may need to be updated when max hits reached
  // Get one to test with for free from https://magic.link/
  apiKey: 'pk_live_4794E79804951360',
  userEmail: localStorage.getItem('magicUserEmail')
})

export const initWeb3Onboard = init({
  wallets: [
    injected,
    ledger,
    coinbase,
    trezor,
    walletConnect,
    gnosis,
    magic,
    fortmatic,
    keepkey,
    torus,
    portis
    
  ],
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum',
      rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`
    },
    {
      id: '0x3',
      token: 'tROP',
      label: 'Ropsten',
      rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`
    },
    {
      id: '0x4',
      token: 'rETH',
      label: 'Rinkeby',
      rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`
    },
    {
      id: '0x5',
      token: 'gETH',
      label: 'Goerli',
      rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`
    },
    {
      id: '0x38',
      token: 'BNB',
      label: 'Binance',
      rpcUrl: 'https://bsc-dataseed.binance.org/'
    },
    {
      id: '0x89',
      token: 'MATIC',
      label: 'Polygon',
      rpcUrl: 'https://matic-mainnet.chainstacklabs.com'
    },
    {
      id: '0xfa',
      token: 'FTM',
      label: 'Fantom',
      rpcUrl: 'https://rpc.ftm.tools/'
    }
  ],
  appMetadata: {
    name: 'Good Karma Club',
    icon: newLogo,
    logo: newLogo,
    description: 'Good Karma Records is a record label looking to shift how the music industry works with the power of web3 technology. We want to put the power back into the creator’s hands, and propel artists forward in their journey while letting them keep the majority of their revenue, unlike major mainstream labels.',
    recommendedInjectedWallets: [
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
      { name: 'MetaMask', url: 'https://metamask.io' }
    ],
    agreement: {
      version: '1.0.0',
      termsUrl: 'https://www.blocknative.com/terms-conditions',
      privacyUrl: 'https://www.blocknative.com/privacy-policy'
    },
    //gettingStartedGuide: 'https://blocknative.com',
    //explore: 'https://blocknative.com'
  }
})

export function initNotify() {
  return Notify({
    dappId,
    networkId,
    apiUrl,
    onerror: error => console.log(`Notify error: ${error.message}`)
  })
}
