import React, { useState, useEffect, useRef } from 'react'
import { ethers } from 'ethers'
import { initWeb3Onboard, initNotify } from './services'
import { useConnectWallet, useSetChain, useWallets } from '@web3-onboard/react'
import './App.css'
import Swal from 'sweetalert2'
import myNft from "./ABI.json"
import backgroundVideo from './videos/Ext Master Intro.mp4'
import mintVideo1 from './videos/Ext Master Intro.mp4'
import txSigned from './videos/Ext Master Dooropen.mp4'
import loopTX3 from './videos/Ext Master Pulse.mp4'
import txConfirmed4 from './videos/Ext Outrolong.mp4'
import gkIntStatic from './images/gkInt_Web.jpg'
import txFail1 from './videos/Ext Master Doorclose.mp4'
import gkLogoImg from './images/Logo Vector.png'
import ethImg from './images/Ethereum.png'
import bgImage from './images/GK Start Image.png'
import outsideClub1 from './music/Outside_Club_GoodKarma.wav'
import insideClub1 from './music/Inside_Club_GoodKarma_V2.mp3'
import mobileSuccess from './videos/Vertical_Good_Karma_Compressed.mp4'
import mobileBackground from './images/gk-web-vertical.jpg'
import mobileSuccessBackground from './images/successMobile.jpg'
import whiteListAddressArray from './utils/whitelistAddresses.js'
import axios from "axios";
import { createClient } from 'urql'

import {
  getClaimlistMerkleProof,
  getClaimlistMerkleRoot,
} from "./utils/merkle_tree";
import {
  CLAIMLIST_ADDRESSES, 
} from "./utils/constants";

const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY

let provider
const contractAddress = "0x16587e4eBAb9456BceBB968bDE540f7290A6ebc0"; // mainnet

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(ALCHEMY_API_KEY); 


const App = () => {
  const [{ wallet }, connect, disconnect] = useConnectWallet()
  const [{ chains, connectedChain, settingChain }, setChain] = useSetChain()
  const connectedWallets = useWallets()
  const [web3Onboard, setWeb3Onboard] = useState(null)
  const [notify, setNotify] = useState(null)    
  const [loading, setloading] = useState(false);
  const [userAddress, setUserAddress] = useState('')  
  const [ourMessge, setOurMessage]= useState('')   
  const [forLink, setForLink] = useState('')
  const [feedback, setFeedback] = useState('')   

  const [mintClickedDivPopUp, setMintClickedDivPopUp] = useState('hidden');
  const [opacityFormintClickedDivPopUp, setOpacityFormintClickedDivPopU] = useState('0');
  const [TransitionFormintClickedDivPopUp, setTransitionFormintClickedDivPopU] = useState('');

  const [confirmClickedDivPopUp, setConfirmClickedDivPopUp] = useState('hidden');
  const [opacityForClickedDivPopUp, setOpacityForClickedDivPopUp] = useState('0');
  const [TransitionForClickedDivPopUp, setTransitionForClickedDivPopUp] = useState('');

  const [loopedDivPopUp, setLoopedDivPopUp] = useState('hidden');
  const [opacityForloopedDivPopUp, setOpacityForloopedDivPopUp] = useState('1');
  const [TransitionForloopedDivPopUp, setTransitionForloopedDivPopUp] = useState('');

  const [successMintDivPopUp, setSuccessMintDivPopUp] = useState('hidden');
  const [opacityForsuccessMintDivPopUp, setOpacityForsuccessMintDivPopUp] = useState('0');
  const [TransitionForsuccessMintDivPopUp, setTransitionForsuccessMintDivPopUp] = useState('');

  const [gkIntDivPopup, setgkIntDivPopUp] = useState('hidden');
  const [opacityForgkIntDivPopup, setOpacityForgkIntDivPopup] = useState('1');
  const [TransitionForgkIntDivPopup, setTransitionForgkIntDivPopup] = useState('');

  const [txFailDivPopup, setTxFailDivPopUp] = useState('hidden');
  const [opacityFortxFailDivPopup, setOpacityFortxFailDivPopup] = useState('0');
  const [TransitionFortxFailDivPopup, setTransitionFortxFailDivPopup] = useState('');

  const [mintStyleVisible, setMintStyleVisible] = useState(''); //change to ('') to make buttong appear regardless
  const [loadMintStyleVisible, setLoadMintStyleVisible] = useState('hidden'); //change to hidden, to make loading button not appear
  
  const [contentIntroVisible, setContentIntroVisible] = useState('');
  const [contentIntroOpacity, setContentIntroOpacity] = useState('1');

  const [contentOutroVisible, setContentOutroVisible] = useState('hidden');
  const [contentOutroOpacity, setContentOutroOpacity] = useState('0');
  const [contentOutroTransition, setContentOutroTransition] = useState('');


  
  const [mobileOutroOpacity, setMobileOutroOpacity] = useState('0');
  const [mobileOutroVisibility, setMobileOutroVisibility] = useState('hidden');

  const [whitelistCheckYesVisibility, setWhitelistCheckYesVisibility] = useState('none')
  const [whitelistCheckNoVisibility, setWhitelistCheckNoVisibility] = useState('none')

  const [availabilityCounter, setAvailabilityCounter] = useState(0)




  const [feedbackVisibility, setFeedbackVisibiliy] = useState('hidden');
  const [feedbackOpacity, setFeedbackOpacity] = useState('0');
  const [localCount, setLocalCount] = useState(false) 
  const [mobileSuccessDivDisplay, setMobileSuccessDivDisplay] = useState('hidden')

  const [mobileDivDisplay, setMobileDivDisplay] = useState('none')
  
  

  const [counter, setCounter] = useState('');
  const [feedbackCounter, setFeedbackCounter] = useState('')
  const [failtxcounter, setFailTxCounter] = useState('');
  const [gkINTcounter, setgkINTcounter] = useState('');
  const [loadCount, setLoadCount] = useState(0);
  const [audioCounter, setAudioCounter] = useState('');
  //Fade Functions
  const [fadeProp, setFadeProp] = useState('');  

  var txSignId = document.getElementById("txSign")
  function playTxSign() {
    txSignId.play(0);
  }

  var txLoopId = document.getElementById("txLoop")
  function playTxLoop() {
    txLoopId.play();
  }

  var txSuccessId = document.getElementById("txSuccess")
  function playTxSuccess() {
    txSuccessId.play();
  }

  var txFailId = document.getElementById("txFail")
  function playtxFail() {
    txFailId.play();
  }

  var mobileSuccessID = document.getElementById("mobileComponent")
  function playMobileSuccess() {
    mobileSuccessID.play();
  }
  //------------------------------------------------- Video Load Log -------------------------------------------------------

  const setTxSignLoaded = () => {
    console.log("TxSign loaded");
    setLoadCount(loadCount => loadCount + 1)
  };
  const setTxLoopLoaded = () => {
    console.log("TxLoop loaded");
    setLoadCount(loadCount => loadCount + 1)
  };
  const setTxSuccessLoaded = () => {
    console.log("TxSuccess loaded");
    setLoadCount(loadCount => loadCount + 1)
  };
  const setTxFailLoaded = () => {
    console.log("TxFail loaded");
    setLoadCount(loadCount => loadCount + 1)
  };  

  var passedTxnHash = ''

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
    setNotify(initNotify())
  }, [])

  useEffect(() => {   

    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )

    // Check for Magic Wallet user session
    if (connectedWalletsLabelArray.includes('Magic Wallet')) {
      const [magicWalletProvider] = connectedWallets.filter(
        provider => provider.label === 'Magic Wallet'
      )
      async function setMagicUser() {
        try {
          const { email } =
            await magicWalletProvider.instance.user.getMetadata()
          const magicUserEmail = localStorage.getItem('magicUserEmail')
          if (!magicUserEmail || magicUserEmail !== email)
            localStorage.setItem('magicUserEmail', email)
        } catch (err) {
          throw err
        }
      }
      setMagicUser()
    }
  }, [connectedWallets, wallet])

  useEffect(() => {
    if (!wallet?.provider) {
      provider = null
    } else {
      provider = new ethers.providers.Web3Provider(wallet.provider, 'any')      
    }
  }, [wallet])

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        await connect({ autoSelect: previouslyConnectedWallets[0] })
      }
      setWalletFromLocalStorage()
    }
  }, [web3Onboard, connect])  


  useEffect(() => {
    const currentlySelectedAddress = connectedWallets.map( ({accounts}) => accounts[0].address)
    //console.log('just checking', currentlySelectedAddress)
    setUserAddress(currentlySelectedAddress[0]) 
    theMaxSupplyCheck()

    if (currentlySelectedAddress.length !== 0) {
      displayWhiteListButtonCheck()
    }
    else if(currentlySelectedAddress.length === 0){
      console.log('no address yet')
    }else{
      console.log('something wrong')
    }
  })  

  const switchNetwork = async () => {   
   
    try {        
      await wallet.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x5' }], // Check networks.js for hexadecimal network ids
      })
      setOurMessage('')

    } catch (error) {
      // This error code means that the chain we want has not been added to MetaMask
      // In this case we ask the user to add it to their MetaMask
      setOurMessage('')
      
      console.log(error)
    }    
  }
  
  async function claimPublic() {
    setMintButtonClicked(true)
    const _amount = 1
    const currentlySelectedAddress = connectedWallets.map( ({accounts}) => accounts[0].address)

    //check if we have a conneted wallet
    if(currentlySelectedAddress.length !== 0){    

      if(connectedChain.id !== '0x5'){  
        setOurMessage('Please confirm the network change')        
        await switchNetwork()         

      }else{          
        try {                  
          //setloading(true)
          let provider = new ethers.providers.Web3Provider(wallet.provider);
          console.log('this is the provider', provider)
          const signer = provider.getSigner()                 
          const contractInstance = new ethers.Contract(contractAddress, myNft.abi, provider)   
        
          async function getGasPrice() {
            let feeData = await provider.getFeeData()
            return feeData.gasPrice
          }

          async function getNonce(signer) {
            return (await signer).getTransactionCount()
          }

          if (currentlySelectedAddress.length !== 0) {            
            const nonce = await getNonce(signer)
            const gasFee = await getGasPrice()   
            
            let rawTxn = await contractInstance.populateTransaction.publicSaleMint(_amount, {
              /* global BigInt */  
              value: BigInt((1000000000000000 * _amount).toString()),
              gasLimit: (285000 * _amount),
              nonce: nonce
            })
            console.log("...Submitting transaction with gas price of:", ethers.utils.formatUnits(gasFee, "gwei"), "in gwei - & nonce:", nonce)
            let signedTxn = signer.sendTransaction(rawTxn)
            let reciept = (await signedTxn).wait()  

            // HERE WE HAVE the sign transaction function
            //chnage stuff here
            //so this is what appears while the tx is pending

            setFeedback('Loading')            
            setloading(true) // make the loading bubble disappear after clicked confirm           
            setMintClickedDivPopUp('hidden')
            setLoopedDivPopUp('hidden')
            setTxFailDivPopUp('hidden')
            setConfirmClickedDivPopUp('')
            setContentIntroVisible('hidden')
            setContentIntroOpacity('0')
            setTransitionForClickedDivPopUp('opacity 3s')
            setOpacityForClickedDivPopUp('1')
            setFadeProp({fade: 'fade-out',});
            playTxSign()            
            setCounter(6)          
            
            const myHash = ((await signedTxn).hash)   
            const returnMyHash = () => {   //used to pass it as a global variabe for the catch to display the revert reason       
              passedTxnHash = myHash  
              return passedTxnHash          
            }
            returnMyHash()            

            if (reciept) {    
              //setForLink((await signedTxn).hash)
              console.log("Transaction is successful!!!" + '\n' + "Transaction Hash:", (await signedTxn).hash + '\n' + "Block Number: " + (await reciept).blockNumber + '\n' + "Navigate to https://goerli.etherscan.io/tx/" + (await signedTxn).hash, "to see your transaction")
              let prefixLink = ['https://goerli.etherscan.io/tx/']
              let suffixLink = (await signedTxn).hash
              let fullLink = prefixLink+suffixLink              
              setForLink(fullLink)   

              //HERE WE HAVE when succesfully minted 
              //change stuff here
              setloading(false)                
              setFeedback('') 
              setConfirmClickedDivPopUp('hidden')
              setContentIntroVisible('hidden')
              setContentIntroOpacity('0')
              setTxFailDivPopUp('hidden')
              setSuccessMintDivPopUp('')
              setTransitionForsuccessMintDivPopUp('opacity 2s')
              setOpacityForsuccessMintDivPopUp('1')
              playTxSuccess() 
              setgkINTcounter(28)
              setAudioCounter(8)   
              setMobileSuccessDivDisplay('')  
              setMobileOutroOpacity('1')    
              setMobileOutroVisibility('')       
                          
            } else {
              console.log("Error submitting transaction")          
              setloading(false)                     
            }           
          }
          else {
            console.log("Need to connect a wallet first")          
            setloading(false)
            setFeedback('NEED TO CONNECT WALLET FIRST')              
          }  
        } catch (e) {      
          console.log("Error Caught in Catch Statement: ", e)  
            
          if(e.message.includes("User denied transaction signature")){
            setConfirmClickedDivPopUp('hidden')
            setLoopedDivPopUp('hidden')
            setMintClickedDivPopUp('hidden')
            setSuccessMintDivPopUp('hidden')
            setTxFailDivPopUp('hidden')
            setContentIntroVisible('')
            setContentIntroOpacity('1')            
            setFeedback('USER DENIED TRANSACTION \n PLEASE TRY AGAIN')
            console.log('USER DENIED TRANSACTION \n PLEASE TRY AGAIN')
            setFeedbackCounter(10)
            setFeedbackOpacity('1')
            setFeedbackVisibiliy('')
            setloading(false)                    
            return 0;
          }else{            
                       
          }               
            
          const txn = await web3.eth.getTransaction(passedTxnHash)
          console.log("transaction hash: ", passedTxnHash)
          console.log("trying to do getTransaction", txn)
              
          let replay_tx = {
            to: txn['to'],
            from: txn['from'],
            value: txn['value'],
            data: txn['input'],
          }      
              
          try{
            const pullCall = await web3.eth.call(replay_tx, txn.blockNumber)
            console.log("Working ok", pullCall)
          }catch (error){
            console.log("error is from long stuff here:" ,error)   
            if(error == "Error: Returned error: execution reverted: Public mint has not begun yet"){   
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('SALE HAS NOT BEGUN')
              console.log("SALE HAS NOT BEGUN")
              setloading(false)                     
            }else if(error == "Error: Returned error: execution reverted: Exceeds max per address"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('CANNOT MINT MORE THAN \n 1 NFT PER WALLET')
              console.log("CANNOT MINT MORE THAN \n 1 NFT PER WALLET")
              setloading(false)                   
            }else if(error == "Error: Returned error: execution reverted: Incorrect funds"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('INCORRECT FUNDS SENT')
              console.log("INCORRECT FUNDS SENT")
              setloading(false)               
            }else if(error == "Error: Returned error: execution reverted: No more NFTs left"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('ALL NFTs ARE SOLD OUT')
              console.log("ALL NFTs ARE SOLD OUT")
              setloading(false)    
            }else{
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('MINTING FAILED \n PLEASE TRY AGAIN')
              console.log("MINTING FAILED \n PLEASE TRY AGAIN")                  
            }       
          }   
          setloading(false)   
        }
      }
    }else{
      setFadeProp({fade: 'fade-in',});
      setTxFailDivPopUp('')
      setOpacityFortxFailDivPopup('1')
      playtxFail()
      setFailTxCounter(8)     
      setFeedback('MINTING FAILED \n PLEASE TRY AGAIN')
      console.log("MINTING FAILED \n PLEASE TRY AGAIN")             
    }
  }  

  async function claimPublicMobile() {
    setMintButtonClicked(true)
    const _amount = 1
    const currentlySelectedAddress = connectedWallets.map( ({accounts}) => accounts[0].address)

    //check if we have a conneted wallet
    if(currentlySelectedAddress.length !== 0){    

      if(connectedChain.id !== '0x5'){  
        setOurMessage('Please confirm the network change')        
        await switchNetwork()         

      }else{          
        try {                  
          //setloading(true)
          let provider = new ethers.providers.Web3Provider(wallet.provider);
          console.log('this is the provider', provider)
          const signer = provider.getSigner()                 
          const contractInstance = new ethers.Contract(contractAddress, myNft.abi, provider)   
        
          async function getGasPrice() {
            let feeData = await provider.getFeeData()
            return feeData.gasPrice
          }

          async function getNonce(signer) {
            return (await signer).getTransactionCount()
          }

          if (currentlySelectedAddress.length !== 0) {            
            const nonce = await getNonce(signer)
            const gasFee = await getGasPrice()   
            
            let rawTxn = await contractInstance.populateTransaction.publicSaleMint(_amount, {
              /* global BigInt */  
              value: BigInt((1000000000000000 * _amount).toString()),
              gasLimit: (285000 * _amount),
              nonce: nonce
            })
            console.log("...Submitting transaction with gas price of:", ethers.utils.formatUnits(gasFee, "gwei"), "in gwei - & nonce:", nonce)
            let signedTxn = signer.sendTransaction(rawTxn)
            let reciept = (await signedTxn).wait()  

            // HERE WE HAVE the sign transaction function
            //chnage stuff here
            //so this is what appears while the tx is pending

            setFeedback('Loading')            
            setloading(true) // make the loading bubble disappear after clicked confirm           
            setMintClickedDivPopUp('hidden')
            setLoopedDivPopUp('hidden')
            setTxFailDivPopUp('hidden')
            setConfirmClickedDivPopUp('')
            setContentIntroVisible('hidden')
            setContentIntroOpacity('0')
            setTransitionForClickedDivPopUp('opacity 3s')
            setOpacityForClickedDivPopUp('1')
            setFadeProp({fade: 'fade-out',});
            //playTxSign()            
            //setCounter(6)          
            
            const myHash = ((await signedTxn).hash)   
            const returnMyHash = () => {   //used to pass it as a global variabe for the catch to display the revert reason       
              passedTxnHash = myHash  
              return passedTxnHash          
            }
            returnMyHash()            

            if (reciept) {    
              //setForLink((await signedTxn).hash)
              console.log("Transaction is successful!!!" + '\n' + "Transaction Hash:", (await signedTxn).hash + '\n' + "Block Number: " + (await reciept).blockNumber + '\n' + "Navigate to https://goerli.etherscan.io/tx/" + (await signedTxn).hash, "to see your transaction")
              let prefixLink = ['https://goerli.etherscan.io/tx/']
              let suffixLink = (await signedTxn).hash
              let fullLink = prefixLink+suffixLink              
              setForLink(fullLink)   

              //HERE WE HAVE when succesfully minted 
              //change stuff here
              setloading(false)                
              setFeedback('') 
              setConfirmClickedDivPopUp('hidden')
              setContentIntroVisible('hidden')
              setContentIntroOpacity('0')
              setTxFailDivPopUp('hidden')
              setSuccessMintDivPopUp('')
              setTransitionForsuccessMintDivPopUp('opacity 2s')
              setOpacityForsuccessMintDivPopUp('1')
              //playTxSuccess() 
              //setgkINTcounter(28)
              setAudioCounter(8)   
              setMobileSuccessDivDisplay('')  
              setMobileOutroOpacity('1')    
              setMobileOutroVisibility('')       
                          
            } else {
              console.log("Error submitting transaction")          
              setloading(false)                     
            }           
          }
          else {
            console.log("Need to connect a wallet first")          
            setloading(false)
            setFeedback('NEED TO CONNECT WALLET FIRST')              
          }  
        } catch (e) {      
          console.log("Error Caught in Catch Statement: ", e)  
            
          if(e.message.includes("User denied transaction signature")){
            setConfirmClickedDivPopUp('hidden')
            setLoopedDivPopUp('hidden')
            setMintClickedDivPopUp('hidden')
            setSuccessMintDivPopUp('hidden')
            setTxFailDivPopUp('hidden')
            setContentIntroVisible('')
            setContentIntroOpacity('1')            
            setFeedback('USER DENIED TRANSACTION \n PLEASE TRY AGAIN')
            console.log('USER DENIED TRANSACTION \n PLEASE TRY AGAIN')
            setFeedbackCounter(10)
            setFeedbackOpacity('1')
            setFeedbackVisibiliy('')
            setloading(false)                    
            return 0;
          }else{            
                       
          }               
            
          const txn = await web3.eth.getTransaction(passedTxnHash)
          console.log("transaction hash: ", passedTxnHash)
          console.log("trying to do getTransaction", txn)
              
          let replay_tx = {
            to: txn['to'],
            from: txn['from'],
            value: txn['value'],
            data: txn['input'],
          }      
              
          try{
            const pullCall = await web3.eth.call(replay_tx, txn.blockNumber)
            console.log("Working ok", pullCall)
          }catch (error){
            console.log("error is from long stuff here:" ,error)   
            if(error == "Error: Returned error: execution reverted: Public mint has not begun yet"){   
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('SALE HAS NOT BEGUN')
              console.log("SALE HAS NOT BEGUN")
              setloading(false)                     
            }else if(error == "Error: Returned error: execution reverted: Exceeds max per address"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('CANNOT MINT MORE THAN \n 1 NFT PER WALLET')
              console.log("CANNOT MINT MORE THAN \n 1 NFT PER WALLET")
              setloading(false)                   
            }else if(error == "Error: Returned error: execution reverted: Incorrect funds"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('INCORRECT FUNDS SENT')
              console.log("INCORRECT FUNDS SENT")
              setloading(false)               
            }else if(error == "Error: Returned error: execution reverted: No more NFTs left"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('ALL NFTs ARE SOLD OUT')
              console.log("ALL NFTs ARE SOLD OUT")
              setloading(false)    
            }else{
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('MINTING FAILED \n PLEASE TRY AGAIN')
              console.log("MINTING FAILED \n PLEASE TRY AGAIN")                  
            }       
          }   
          setloading(false)   
        }
      }
    }else{
      setFadeProp({fade: 'fade-in',});
      setTxFailDivPopUp('')
      setOpacityFortxFailDivPopup('1')
      //playtxFail()
      setFailTxCounter(8)     
      setFeedback('MINTING FAILED \n PLEASE TRY AGAIN')
      console.log("MINTING FAILED \n PLEASE TRY AGAIN")             
    }
  }  


  async function claimWhitelist() {
    setMintButtonClicked(true)
    const quantity = 1
    //const _maxAmount = 1
    const currentlySelectedAddress = connectedWallets.map( ({accounts}) => accounts[0].address)

    //check if we have a conneted wallet
    if(currentlySelectedAddress.length !== 0){    
      /*const claimlistRoot = getClaimlistMerkleRoot(CLAIMLIST_ADDRESSES);
      const _proof = getClaimlistMerkleProof(CLAIMLIST_ADDRESSES, currentlySelectedAddress[0], "1")

      console.log('this is the merke root', claimlistRoot)
      console.log('this is the proof', _proof)*/

      if(connectedChain.id !== '0x5'){  
        setOurMessage('Please confirm the network change')        
        await switchNetwork()         

      }else{          
        try {                  
          //setloading(true)
          let provider = new ethers.providers.Web3Provider(wallet.provider);
          console.log('this is the provider', provider)
          const signer = provider.getSigner()                 
          const contractInstance = new ethers.Contract(contractAddress, myNft.abi, provider)   
        
          async function getGasPrice() {
            let feeData = await provider.getFeeData()
            return feeData.gasPrice
          }

          async function getNonce(signer) {
            return (await signer).getTransactionCount()
          }

          if (currentlySelectedAddress.length !== 0) {            
            const nonce = await getNonce(signer)
            const gasFee = await getGasPrice()     
            
            let _merkleProof 
            await axios.get("https://bolder-better-existence.glitch.me/", { params: { address: currentlySelectedAddress[0] },}).then((response) => (_merkleProof = response.data.proof));
    
            
            let rawTxn = await contractInstance.populateTransaction.mintPreSaleTokens(quantity,_merkleProof, {
              /* global BigInt */  
              value: BigInt((1000000000000000 * quantity).toString()),
              gasLimit: (285000 * quantity),
              nonce: nonce
            })
            console.log("...Submitting transaction with gas price of:", ethers.utils.formatUnits(gasFee, "gwei"), "in gwei - & nonce:", nonce)
            let signedTxn = signer.sendTransaction(rawTxn)
            let reciept = (await signedTxn).wait()  

            // HERE WE HAVE the sign transaction function
            //chnage stuff here
            //so this is what appears while the tx is pending

            setFeedback('Loading')            
            setloading(true) // make the loading bubble disappear after clicked confirm           
            setMintClickedDivPopUp('hidden')
            setLoopedDivPopUp('hidden')
            setTxFailDivPopUp('hidden')
            setConfirmClickedDivPopUp('')
            setContentIntroVisible('hidden')
            setContentIntroOpacity('0')
            setTransitionForClickedDivPopUp('opacity 3s')
            setOpacityForClickedDivPopUp('1')
            setFadeProp({fade: 'fade-out',});
            playTxSign()            
            setCounter(6)      

            const myHash = ((await signedTxn).hash)            

            const returnMyHash = () => {   //used to pass it as a global variabe for the catch to display the revert reason       
              passedTxnHash = myHash  
              return passedTxnHash          
            }
            returnMyHash()            

            if (reciept) {    
              //setForLink((await signedTxn).hash)
              console.log("Transaction is successful!!!" + '\n' + "Transaction Hash:", (await signedTxn).hash + '\n' + "Block Number: " + (await reciept).blockNumber + '\n' + "Navigate to https://goerli.etherscan.io/tx/" + (await signedTxn).hash, "to see your transaction")
              let prefixLink = ['https://goerli.etherscan.io/tx/']
              let suffixLink = (await signedTxn).hash
              let fullLink = prefixLink+suffixLink              
              setForLink(fullLink)   

              //HERE WE HAVE when succesfully minted 
              //change stuff here
              setloading(false)                
              setFeedback('') 
              setConfirmClickedDivPopUp('hidden')
              setContentIntroVisible('hidden')
              setContentIntroOpacity('0')
              setTxFailDivPopUp('hidden')
              setSuccessMintDivPopUp('')
              setTransitionForsuccessMintDivPopUp('opacity 2s')
              setOpacityForsuccessMintDivPopUp('1')
              playTxSuccess() 
              setgkINTcounter(28)
              setAudioCounter(8)   
              setMobileSuccessDivDisplay('')  
              setMobileOutroOpacity('1')    
              setMobileOutroVisibility('')                      
                          
            } else {
              console.log("Error submitting transaction")          
              setloading(false)   
                  
            }           
          }
          else {
            console.log("Need to connect a wallet first")          
            setloading(false)
            setFeedback('NEED TO CONNECT WALLET FIRST')              
          }  
        } catch (e) {      
          console.log("Error Caught in Catch Statement: ", e)  
            
          if(e.message.includes("User denied transaction signature")){
            setConfirmClickedDivPopUp('hidden')
            setLoopedDivPopUp('hidden')
            setMintClickedDivPopUp('hidden')
            setSuccessMintDivPopUp('hidden')
            setTxFailDivPopUp('hidden')
            setContentIntroVisible('')
            setContentIntroOpacity('1')            
            setFeedback('USER DENIED TRANSACTION \n PLEASE TRY AGAIN')
            console.log('USER DENIED TRANSACTION \n PLEASE TRY AGAIN')
            setFeedbackCounter(10)
            setFeedbackOpacity('1')
            setFeedbackVisibiliy('')
            setloading(false)                    
            return 0;
          }else{            
                       
          }               
            
          const txn = await web3.eth.getTransaction(passedTxnHash)
          console.log("transaction hash: ", passedTxnHash)
          console.log("trying to do getTransaction", txn)
              
          let replay_tx = {
            to: txn['to'],
            from: txn['from'],
            value: txn['value'],
            data: txn['input'],
          }      
              
          try{
            const pullCall = await web3.eth.call(replay_tx, txn.blockNumber)
            console.log("Working ok", pullCall)
          }catch (error){
            console.log("error is from long stuff here:" ,error)   
            if(error == "Error: Returned error: execution reverted: Whitelist mint is not active yet"){   
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('WHITELIST SALE HAS NOT BEGUN')
              console.log("WHITELIST SALE HAS NOT BEGUN")
              setloading(false)                     
            }else if(error == "Error: Returned error: execution reverted: Invalid MerkleProof"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('INVALID MERKLE PROOF \n YOU ARE NOT ON THE WHITELIST')
              console.log("INVALID MERKLE PROOF \n YOU ARE NOT ON THE WHITELIST")
              setloading(false)                   
            }else if(error == "Error: Returned error: execution reverted: Exceeds max per transaction"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('CANNOT MINT MORE THAN \n 1 NFT PER WALLET')
              console.log("CANNOT MINT MORE THAN \n 1 NFT PER WALLET")
              setloading(false)                   
            }else if(error == "Error: Returned error: execution reverted: Incorrect funds"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('INCORRECT FUNDS SENT')
              console.log("INCORRECT FUNDS SENT")
              setloading(false)               
            }else if(error == "Error: Returned error: execution reverted: Exceeded max available to purchase at a time"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('CLAIMLIST ALREADY CLAIMED')
              console.log("CLAIMLIST ALREADY CLAIMED")
              setloading(false)    
            }else if(error == "Error: Returned error: execution reverted: No more NFTs left"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('ALL NFTs ARE SOLD OUT')
              console.log("ALL NFTs ARE SOLD OUT")
              setloading(false)    
            }else{
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              playtxFail()
              setFailTxCounter(8)     
              setFeedback('MINTING FAILED \n PLEASE TRY AGAIN')
              console.log("MINTING FAILED \n PLEASE TRY AGAIN")                  
            }       
          }   
          setloading(false)   
        }
      }
    }else{
      setFadeProp({fade: 'fade-in',});
      setTxFailDivPopUp('')
      setOpacityFortxFailDivPopup('1')
      playtxFail()
      setFailTxCounter(8)     
      setFeedback('MINTING FAILED \n PLEASE TRY AGAIN')
      console.log("MINTING FAILED \n PLEASE TRY AGAIN")             
    }
  }  

  async function claimWhitelistMobile() {
    setMintButtonClicked(true)
    const quantity = 1
   // const _maxAmount = 1
    const currentlySelectedAddress = connectedWallets.map( ({accounts}) => accounts[0].address)

    //check if we have a conneted wallet
    if(currentlySelectedAddress.length !== 0){    
      /*const claimlistRoot = getClaimlistMerkleRoot(CLAIMLIST_ADDRESSES);
      const _proof = getClaimlistMerkleProof(CLAIMLIST_ADDRESSES, currentlySelectedAddress[0], "1")

      console.log('this is the merke root', claimlistRoot)
      console.log('this is the proof', _proof)*/

      if(connectedChain.id !== '0x5'){  
        setOurMessage('Please confirm the network change')        
        await switchNetwork()         

      }else{          
        try {                  
          //setloading(true)
          let provider = new ethers.providers.Web3Provider(wallet.provider);
          console.log('this is the provider', provider)
          const signer = provider.getSigner()                 
          const contractInstance = new ethers.Contract(contractAddress, myNft.abi, provider)   
        
          async function getGasPrice() {
            let feeData = await provider.getFeeData()
            return feeData.gasPrice
          }

          async function getNonce(signer) {
            return (await signer).getTransactionCount()
          }

          if (currentlySelectedAddress.length !== 0) {            
            const nonce = await getNonce(signer)
            const gasFee = await getGasPrice()             
            
            let _merkleProof 
            await axios.get("https://bolder-better-existence.glitch.me/", { params: { address: currentlySelectedAddress[0] },}).then((response) => (_merkleProof = response.data.proof));
    
            
            let rawTxn = await contractInstance.populateTransaction.mintPreSaleTokens(quantity,_merkleProof, {
              /* global BigInt */  
              value: BigInt((1000000000000000 * quantity).toString()),
              gasLimit: (285000 * quantity),
              nonce: nonce
            })
            console.log("...Submitting transaction with gas price of:", ethers.utils.formatUnits(gasFee, "gwei"), "in gwei - & nonce:", nonce)
            let signedTxn = signer.sendTransaction(rawTxn)
            let reciept = (await signedTxn).wait()  

            // HERE WE HAVE the sign transaction function
            //chnage stuff here
            //so this is what appears while the tx is pending

            setFeedback('Loading')            
            setloading(true) // make the loading bubble disappear after clicked confirm           
            setMintClickedDivPopUp('hidden')
            setLoopedDivPopUp('hidden')
            setTxFailDivPopUp('hidden')
            setConfirmClickedDivPopUp('')
            setContentIntroVisible('hidden')
            setContentIntroOpacity('0')
            setTransitionForClickedDivPopUp('opacity 3s')
            setOpacityForClickedDivPopUp('1')
            setFadeProp({fade: 'fade-out',});
            //playTxSign()            
            //setCounter(6)      

            const myHash = ((await signedTxn).hash)            

            const returnMyHash = () => {   //used to pass it as a global variabe for the catch to display the revert reason       
              passedTxnHash = myHash  
              return passedTxnHash          
            }
            returnMyHash()            

            if (reciept) {    
              //setForLink((await signedTxn).hash)
              console.log("Transaction is successful!!!" + '\n' + "Transaction Hash:", (await signedTxn).hash + '\n' + "Block Number: " + (await reciept).blockNumber + '\n' + "Navigate to https://goerli.etherscan.io/tx/" + (await signedTxn).hash, "to see your transaction")
              let prefixLink = ['https://goerli.etherscan.io/tx/']
              let suffixLink = (await signedTxn).hash
              let fullLink = prefixLink+suffixLink              
              setForLink(fullLink)   

              //HERE WE HAVE when succesfully minted 
              //change stuff here
              setloading(false)                
              setFeedback('') 
              setConfirmClickedDivPopUp('hidden')
              setContentIntroVisible('hidden')
              setContentIntroOpacity('0')
              setTxFailDivPopUp('hidden')
              setSuccessMintDivPopUp('')
              setTransitionForsuccessMintDivPopUp('opacity 2s')
              setOpacityForsuccessMintDivPopUp('1')
              //playTxSuccess() 
              //setgkINTcounter(28)
              setAudioCounter(8)   
              setMobileSuccessDivDisplay('')  
              setMobileOutroOpacity('1')    
              setMobileOutroVisibility('')                      
                          
            } else {
              console.log("Error submitting transaction")          
              setloading(false)   
                  
            }           
          }
          else {
            console.log("Need to connect a wallet first")          
            setloading(false)
            setFeedback('NEED TO CONNECT WALLET FIRST')              
          }  
        } catch (e) {      
          console.log("Error Caught in Catch Statement: ", e)  
            
          if(e.message.includes("User denied transaction signature")){
            setConfirmClickedDivPopUp('hidden')
            setLoopedDivPopUp('hidden')
            setMintClickedDivPopUp('hidden')
            setSuccessMintDivPopUp('hidden')
            setTxFailDivPopUp('hidden')
            setContentIntroVisible('')
            setContentIntroOpacity('1')            
            setFeedback('USER DENIED TRANSACTION \n PLEASE TRY AGAIN')
            console.log('USER DENIED TRANSACTION \n PLEASE TRY AGAIN')
            setFeedbackCounter(10)
            setFeedbackOpacity('1')
            setFeedbackVisibiliy('')
            setloading(false)                    
            return 0;
          }else{            
                       
          }               
            
          const txn = await web3.eth.getTransaction(passedTxnHash)
          console.log("transaction hash: ", passedTxnHash)
          console.log("trying to do getTransaction", txn)
              
          let replay_tx = {
            to: txn['to'],
            from: txn['from'],
            value: txn['value'],
            data: txn['input'],
          }      
              
          try{
            const pullCall = await web3.eth.call(replay_tx, txn.blockNumber)
            console.log("Working ok", pullCall)
          }catch (error){
            console.log("error is from long stuff here:" ,error)   
            if(error == "Error: Returned error: execution reverted: Whitelist mint is not active yet"){   
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('WHITELIST SALE HAS NOT BEGUN')
              console.log("WHITELIST SALE HAS NOT BEGUN")
              setloading(false)                     
            }else if(error == "Error: Returned error: execution reverted: Invalid MerkleProof"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('INVALID MERKLE PROOF \n YOU ARE NOT ON THE WHITELIST')
              console.log("INVALID MERKLE PROOF \n YOU ARE NOT ON THE WHITELIST")
              setloading(false)                   
            }else if(error == "Error: Returned error: execution reverted: Exceeds max per transaction"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('CANNOT MINT MORE THAN \n 1 NFT PER WALLET')
              console.log("CANNOT MINT MORE THAN \n 1 NFT PER WALLET")
              setloading(false)                   
            }else if(error == "Error: Returned error: execution reverted: Incorrect funds"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('INCORRECT FUNDS SENT')
              console.log("INCORRECT FUNDS SENT")
              setloading(false)               
            }else if(error == "Error: Returned error: execution reverted: Exceeded max available to purchase at a time"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('CLAIMLIST ALREADY CLAIMED')
              console.log("CLAIMLIST ALREADY CLAIMED")
              setloading(false)    
            }else if(error == "Error: Returned error: execution reverted: No more NFTs left"){
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('ALL NFTs ARE SOLD OUT')
              console.log("ALL NFTs ARE SOLD OUT")
              setloading(false)    
            }else{
              setFadeProp({fade: 'fade-in',});
              setTxFailDivPopUp('')
              setOpacityFortxFailDivPopup('1')
              //playtxFail()
              setFailTxCounter(8)     
              setFeedback('MINTING FAILED \n PLEASE TRY AGAIN')
              console.log("MINTING FAILED \n PLEASE TRY AGAIN")                  
            }       
          }   
          setloading(false)   
        }
      }
    }else{
      setFadeProp({fade: 'fade-in',});
      setTxFailDivPopUp('')
      setOpacityFortxFailDivPopup('1')
      //playtxFail()
      setFailTxCounter(8)     
      setFeedback('MINTING FAILED \n PLEASE TRY AGAIN')
      console.log("MINTING FAILED \n PLEASE TRY AGAIN")             
    }
  }  



  //this useeffect controls the counter, so once counter is 0, then we make the loop video NOT hidden
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

    if(counter === 1){
      //make confirm gif disappear, and make the looped gif appear
      setLoopedDivPopUp('')
    }

    if(counter === 0){
      //make confirm gif disappear, and make the looped gif appear
      setConfirmClickedDivPopUp('hidden')
      playTxLoop()
    }
    
  }, [counter]); 
  
  //this useeffect controls the counter, so once counter is 0, then we make the loop video NOT hidden
  useEffect(() => {
    failtxcounter > 0 && setTimeout(() => setFailTxCounter(failtxcounter - 1), 1000);

    if(failtxcounter === 2){      
      setLoopedDivPopUp('hidden')   
      setConfirmClickedDivPopUp('hidden')
      setMintClickedDivPopUp('hidden')
      setSuccessMintDivPopUp('hidden')   
    }

    if(failtxcounter === 0){
      //make confirm gif disappear, and make the looped gif appear
      setConfirmClickedDivPopUp('hidden')
      setLoopedDivPopUp('hidden')
      setMintClickedDivPopUp('hidden')
      setSuccessMintDivPopUp('hidden')
      setTxFailDivPopUp('hidden')
      setFeedbackCounter(10)
      setFeedbackOpacity('1')
      setFeedbackVisibiliy('')
      setContentIntroVisible('')
      setContentIntroOpacity('1')
      
    }
  }, [failtxcounter]);

  useEffect(() => {
    feedbackCounter > 0 && setTimeout(() => setFeedbackCounter(feedbackCounter - 1), 1000);

    if(feedbackCounter === 2){
      setFeedbackOpacity('0')  
    }

    if(feedbackCounter === 0){  
      setFeedbackVisibiliy('hidden')      
      setFeedback('')      
    }
  }, [feedbackCounter]);


  //this useeffect controls the counter, so once counter is 0, then we make the loop video NOT hidden
  useEffect(() => {
    gkINTcounter > 0 && setTimeout(() => setgkINTcounter(gkINTcounter - 1), 1000);

    if(gkINTcounter === 5){
      //make confirm gif disappear, and make the looped gif appear
      setgkIntDivPopUp('')
      setLoopedDivPopUp('hidden')      
    }    

    if(gkINTcounter === 15){
      setContentOutroOpacity('1')
      setContentOutroVisible('')        
      setContentOutroTransition('opacity 3s')        
    }
  
    if(gkINTcounter === 0){
      //make confirm gif disappear, and make the looped gif appear
      setConfirmClickedDivPopUp('hidden')
      setLoopedDivPopUp('hidden')
      setMintClickedDivPopUp('hidden')
      setSuccessMintDivPopUp('hidden')
      setTxFailDivPopUp('hidden')
      setgkIntDivPopUp('')
    }
  }, [gkINTcounter]);

  //this useeffect controls the counter, so once counter is 0, then we make the loop video NOT hidden
  useEffect(() => {     
    if(loadCount === 4){
      //make confirm gif disappear, and make the looped gif appear
      console.log('All Videos Loaded')
      setMintStyleVisible('')
      setLoadMintStyleVisible('hidden')        
    }        
      
  }, [loadCount]);

    
  const outsidePlay = new Audio(outsideClub1);
  const insidePlay = new Audio(insideClub1)
  insidePlay.loop = true
  outsidePlay.loop = true
  
  const startOutsideClub = () => {
    
    if(localCount === false){
      exterior.current.play();
      setLocalCount(true)
    }
    
  };

  const pauseOutsideClub = () => {
    exterior.current.pause()
  }
    
  const startInsideclub = () => {
    interior.current.play()
  }

  const pauseAudioFunc = () => {    
    exterior.current.pause();   
    interior.current.pause();    
  };

  const [mintButtonClicked, setMintButtonClicked] = useState(false)

  const exterior = useRef(); //outside
  const interior = useRef() //inside

  useEffect(() => {
    let buttonClikcedTrueOrFalse = mintButtonClicked
    //console.log('buttonClikcedTrueOrFalse var = ',buttonClikcedTrueOrFalse)     

    document.addEventListener('visibilitychange', function() {
      if(document.hidden){
        pauseAudioFunc()
        //console.log('Page is hidden from user view');            
      }
      else{
          //console.log('Page is in user view');           
          //console.log('this is our mint clicked var = ', buttonClikcedTrueOrFalse)
          if(buttonClikcedTrueOrFalse === false)
          {
            //console.log('it is false')
          }
          else if(buttonClikcedTrueOrFalse === true){

            if(audioCounter === 0){
              exterior.current.pause();
              interior.current.play();
            }
            else if(audioCounter !== 0){
              exterior.current.play();
            }
            else{
              
            }

            //exterior.current.play();

            
            //console.log('it is true')
          }
          else{
            console.log('no change yet')
          }         
      }
    });
  });

  useEffect(() => {
    audioCounter > 0 && setTimeout(() => setAudioCounter(audioCounter - 1), 1000);

    if(audioCounter === 0){
    pauseOutsideClub();
    startInsideclub();
    setMobileDivDisplay('')
    playMobileSuccess();
    
    
  }}, 

  [audioCounter]);  

  //all of our visibilities, from hidden to shown, or the other way around
  const mintClicked = {      
    visibility: mintClickedDivPopUp,
    opacity: opacityFormintClickedDivPopUp,
    transition: TransitionFormintClickedDivPopUp         
  };  

  const confirmClicked = {      
    visibility: confirmClickedDivPopUp,
    opacity: opacityForClickedDivPopUp,
    transition: TransitionForClickedDivPopUp         
  };  

  const loopingTxConfirm = {      
    visibility: loopedDivPopUp,
    opacity: opacityForloopedDivPopUp,
    transition: TransitionForloopedDivPopUp
  };    

  const successMint = {      
    visibility: successMintDivPopUp,
    opacity: opacityForsuccessMintDivPopUp,
    transition: TransitionForsuccessMintDivPopUp
  }; 

  const txFail = {      
    visibility: txFailDivPopup,
    opacity: opacityFortxFailDivPopup,
    transition: 'opacity 3s ease'    
  }; 

  const gkInt = {      
    visibility: gkIntDivPopup,
    opacity: opacityForgkIntDivPopup,
    transition: TransitionForgkIntDivPopup
  }; 

  const mintStyle = {
    visibility: mintStyleVisible
  }

  const loadMintStyle = {
    visibility: loadMintStyleVisible
  }

  const contentIntro ={
    opacity: contentIntroOpacity,
    visibility: contentIntroVisible,
    transition: 'opacity 2s ease-in-out, visibility 2s ease-in-out'
  }

  const contentOutro = {
    opacity: contentOutroOpacity,
    visibility: contentOutroVisible,    
    transition: contentOutroTransition,
    position: 'fixed',
    zIndex: '50'
  }

  const feedbackDivCSSEdit = {
    visibility: feedbackVisibility,
    opacity: feedbackOpacity,
    transition: 'opacity 2s ease-in-out, visibility 2s ease-in-out'    
  }

  const mobileVideoDiv = {
    display: mobileDivDisplay,
    position: 'fixed',
    zIndex: 50,
    top: '0'    
  }

  const mobileSuccessDiv = {
    visibility: mobileSuccessDivDisplay,
    zIndex:'45'    
  }

  const mobilecontentOutro = {
    opacity: mobileOutroOpacity,
    visibility: mobileOutroVisibility,    
    transition: 'opacity 3s',
    position: 'fixed',
    zIndex: '50'    
  }

  const whitelistCheckYes = {    
    display: whitelistCheckYesVisibility       
  }

  const whitelistCheckNo = {    
    display: whitelistCheckNoVisibility       
  }


  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 479px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 479px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  async function displayWhiteListButtonCheck() {
    const currentlySelectedAddress = connectedWallets.map( ({accounts}) => accounts[0].address)    
    let searchAddress = await currentlySelectedAddress[0]      

    if(whiteListAddressArray.includes(await searchAddress.toLowerCase())){
      //console.log('yes')
      //console.log(searchAddress.toLowerCase())
      setWhitelistCheckYesVisibility('')
        
    }
    else{
      //console.log('no')
      //console.log(searchAddress.toLowerCase())
      setWhitelistCheckNoVisibility('')
    }

  }

  async function theMaxSupplyCheck() {                        
    const contractInstance = new ethers.Contract(contractAddress, myNft.abi, provider) 
    let totalSupplyAmount = await contractInstance.totalSupply()
    console.log('total suply', totalSupplyAmount.toString())  
   
    let totalSupplyIs = 1000   
    let leftForMint = totalSupplyIs - totalSupplyAmount

    setAvailabilityCounter(leftForMint)         
  }
  

  
    
  
  

  if (!web3Onboard || !notify) return <div>Loading...</div>

  


  return (
    <div className="HomeApp"> 
    <div >
      {matches && (<div className='backgroundMobileCSS'>
        <video className='backgroundVideoCSS' autoPlay loop muted style={{position: "absolute",  height:"100%",  width:"100%",  objectFit:"cover",zIndex: "0"}} >
          <source src={backgroundVideo}  type="video/mp4" />  Your browser won't load the background video
        </video> 
      </div> )}
      {!matches && (<div className='backgroundMobileCSS'>
        <video className='backgroundVideoCSS' autoPlay= 'false' muted style={{display: "none"}} >
          <source src=''  type="video/mp4" />  Your browser won't load the background video
        </video> 
      </div>)}
    </div>

      {/* here is the background video */}
           
      <img className='bgGraphic' src={bgImage}></img>   
      <img className='mobileBackground' src={mobileBackground}></img>
      <div className='blockNativeDivCSS'>
        <div className='selectChainDiv'> 
          {wallet && ( <div className="networkSelect"> <label>Switch Chains</label> {settingChain ? ( <span>Switching Chains...</span> ) : ( <select onChange={({ target: { value } }) => setChain({ chainId: value }) } value={connectedChain?.id} > {chains.map(({ id, label }) => { return (  <option value={id} key={id}> {label} </option> ) })} </select> )} </div> )}
        </div>   
        <div className='connectAndDisconnectDiv'>
          {!wallet && ( <button className="blocknativeButton" onClick={() => { connect() }} >  Connect Wallet  </button>  )}
          {wallet && ( <button className="blocknativeButton" onClick={() => { connect() }} >  Connect Another Wallet </button> )}
          {wallet && ( <button className="blocknativeButton" onClick={() => { disconnect(wallet)
            window.localStorage.removeItem('connectedWallets')
          }} > Disconnect Wallet </button>  )}
          {wallet && wallet?.dashboard && (<button className="blocknativeButton" onClick={wallet?.dashboard} > Open Wallet Dashboard </button> )}
          {wallet && wallet?.type === 'hardware' &&  wallet.accounts[0].address && ( <button className="blocknativeButton" onClick={web3Onboard.accountSelect} > Switch Account  </button> )}
          {ourMessge}
        </div>  
      </div>  

      <div className='feedbackDivCSS' style={feedbackDivCSSEdit}>
        <p>{feedback}</p>
      </div>         

      <div style={mintClicked}>
        <div className="mintClickedDivCSS">  
        {matches && (<video autoPlay loop muted style={{position: "fixed",height:"100vh", width:"100vw", objectFit:"cover", zIndex:"-10" }}>
            <source src={mintVideo1}  type="video/mp4" />  Your browser won't load the background video
          </video>)}
      {!matches && (<video className='mintClickedDivCSS' autoPlay= 'false' muted style={{display: "none"}} >
          <source src=''  type="video/mp4" />  Your browser won't load the background video
        </video> )}
               
        </div>
      </div>

      <div style={mobileVideoDiv}>      
        <div className="confirmVideoForMobile">  
        {matches && (<video id='mobileComponent' muted style={{position: "fixed",height:"100vh", width:"100vw", objectFit:"cover", zIndex:"-5" }}>
            <source src={mobileSuccess}  type="video/mp4" />  Your browser won't load the background video
          </video>   )}
      {!matches && (<video id='mobileComponent' autoPlay= 'false' muted style={{display: "none"}} >
          <source src=''  type="video/mp4" />  Your browser won't load the background video
        </video>   )}
                   
        </div>
      </div>
      



      <div style={confirmClicked}>
        <div className="confirmClickedDivCSS">   
        {matches && (<video muted style={{position: "fixed",height:"100vh", width:"100vw", objectFit:"cover", zIndex:"-10" }} id="txSign" onLoadedData={() => {
          setTxSignLoaded();
        }}>
            <source src={txSigned}  type="video/mp4" />  Your browser won't load the background video
          </video>)}
      {!matches && (<video autoPlay= 'false' muted style={{display: "none"}}  >
          <source src=''  type="video/mp4" />  Your browser won't load the background video
        </video>)}
          

          <p className='loadingText' style={{transition: "opacity 2s ease-in-out, visibility 2s ease-in-out"}}>LOADING</p>
          {loading ? <div className="loading"> <div></div> <div></div> <div></div> <div></div> <div></div> </div> : ""}
                           
        </div>
      </div>

      <div style={loopingTxConfirm}>
        <div className="loopingTxConfirmDivCSS"> 
        {matches && (<video loop muted style={{position: "fixed",height:"100vh", width:"100vw", objectFit:"cover", zIndex:"-10"}} id="txLoop" onLoadedData={() => {
          setTxLoopLoaded();
        }}>
            <source src={loopTX3}  type="video/mp4" />  Your browser won't load the background video
          </video>)}
      {!matches && (<video autoPlay= 'false' muted style={{display: "none"}} >
          <source src=''  type="video/mp4" />  Your browser won't load the background video
        </video>)}
        
          
          <p className='loadingText' style={{transition: "opacity 2s ease-in-out, visibility 2s ease-in-out"}}>LOADING</p>
          {loading ? <div className="loading"> <div></div> <div></div> <div></div> <div></div> <div></div> </div> : ""}
        </div>
      </div>

      <div style={successMint}> 
        <div className="successfullyMintedDivCSS">  

        {matches && (<video muted style={{position: "fixed",height:"100vh", width:"100vw", objectFit:"cover", zIndex:"-10" }} id="txSuccess" onLoadedData={() => {
          setTxSuccessLoaded();
          setloading(false)
          setFeedback('')
        }}>
            <source src={txConfirmed4}  type="video/mp4" />  Your browser won't load the background video
          </video>)}
      {!matches && (<video autoPlay= 'false' muted style={{display: "none"}} >
          <source src=''  type="video/mp4" />  Your browser won't load the background video
        </video>)} 
                          
        </div>
      </div>

      <div style={txFail}>
        <div className="txFailDivCSS"> 
        {matches && (<video muted style={{position: "fixed",height:"100vh", width:"100vw", objectFit:"cover", zIndex:"-10" }} id="txFail" onLoadedData={() => {
          setTxFailLoaded();
        }}>
            <source src={txFail1}  type="video/mp4" />  Your browser won't load the background video
          </video>)}
      {!matches && (<video autoPlay= 'false' muted style={{display: "none"}} >
          <source src=''  type="video/mp4" />  Your browser won't load the background video
        </video>)}  
                          
        </div>
      </div>

      <div style={gkInt}>
        <img className="gkIntCSS" style={{position: "fixed",height:"100vh", width:"100vw", objectFit:"cover", zIndex:"10"}} src={gkIntStatic} id="gkIntID">   
                     
        </img>
        
      </div>

      <img className='mobileSuccessBackgroundCSS' style={mobileSuccessDiv} src={mobileSuccessBackground} ></img>
          

          
      <div className={fadeProp.fade} style={contentIntro}>
        <div className="logohome">
          <a href="https://goodkarmaclub.xyz" className="w-inline-block"><img src={gkLogoImg} loading="lazy" alt="" className="introbrandimg" /></a>
        </div>

        {wallet ?  //if wallet is connected show the div, otherwise show nothing
          <div className="introcontents">
            <div className="introheader">
              <h1 className="introheading">MINT YOUR<br></br>MEMBERSHIP PASS</h1>
            </div>
          </div>
        : ""}

        {wallet ? // if wallet is connected show mint button, otherwise show nothing
          <div className="intromintdetails" style={whitelistCheckYes}>
            <div className="intromintleftdiv">
              <div className="mintfeature">
                <div className="mintfigures">
                  <h1 className="mintfeatureheadinglarge">{availabilityCounter}</h1>
                </div>
                <div className="mintcopy">
                  <h1 className="mintfeatureheading">NFT&#x27;s Available</h1>
                </div>
              </div>
              <div className="mintfeature">
                <div className="mintfigures">
                  <h1 className="mintfeatureheadinglarge">0.0777</h1>
                  <img src={ethImg} className="ethImg" />
                </div>
                <div className="mintcopy">
                  <h1 className="mintfeatureheading">NFT MINT PRICE</h1>
                </div>
              </div>
            </div>
            <div className="intromintbuttoncontain">
              <button className="intromintbutton" style={mintStyle} onClick={() => (claimPublic(),setMintClickedDivPopUp(''),setFeedback(''),startOutsideClub())}>MINT</button>
              <button className="intromintbuttonMobile" style={mintStyle} onClick={() => (claimPublicMobile(),setMintClickedDivPopUp(''),setFeedback(''),startOutsideClub())}>MINT</button>
              <button className="intromintbuttonWhiteList" style={mintStyle} onClick={() => (claimWhitelist(),setMintClickedDivPopUp(''),setFeedback(''),startOutsideClub())}>WHITELIST</button>
              <button className="intromintbuttonWhiteListMobile" style={mintStyle} onClick={() => (claimWhitelistMobile(),setMintClickedDivPopUp(''),setFeedback(''),startOutsideClub())}>WHITELIST</button>
              
              <button className="intromintbutton" style={loadMintStyle}>Loading</button>
            </div>            
          </div>
        : ""}

        {wallet ? // if wallet is connected show mint button, otherwise show nothing
          <div className="intromintdetails" style={whitelistCheckNo}>
            <div className="intromintleftdiv">
              <div className="mintfeature">
                <div className="mintfigures">
                  <h1 className="mintfeatureheadinglarge">{availabilityCounter}</h1>
                </div>
                <div className="mintcopy">
                  <h1 className="mintfeatureheading">NFT&#x27;s Available</h1>
                </div>
              </div>
              <div className="mintfeature">
                <div className="mintfigures">
                  <h1 className="mintfeatureheadinglarge">0.0777</h1>
                  <img src={ethImg} className="ethImg" />
                </div>
                <div className="mintcopy">
                  <h1 className="mintfeatureheading">NFT MINT PRICE</h1>
                </div>
              </div>
            </div>            
            <div className="intromintbuttoncontain" style={whitelistCheckNo}>
              <button className="intromintbutton" style={mintStyle} onClick={() => (claimPublic(),setMintClickedDivPopUp(''),setFeedback(''),startOutsideClub())}>MINT</button>
              <button className="intromintbuttonMobile" style={mintStyle} onClick={() => (claimPublicMobile(),setMintClickedDivPopUp(''),setFeedback(''),startOutsideClub())}>MINT</button>
                
              <button className="intromintbutton" style={loadMintStyle}>Loading</button>
            </div>
          </div>
        : ""}

        {wallet ? "" :
          <div className="introconnectwallet">
            <div className="introconnectinner">
              <h1 className="introconnectheading">Connect Your Wallet to Access</h1>
              <p className="introconnectpara">Please connect you wallet to access this mint.</p>
              <button onClick={() => { connect() }} className="introconnectbutton" style={{textDecoration: "none"}}>Connect Wallet</button>
            </div>
          </div> }
             

        </div>
        {matches && (<div style={contentOutro}>
            <div>
              <div className="back-home">
                <a href="https://goodkarmaclub.xyz" className="backhomebutton w-button"  style={{textDecoration: "none"}}>Return to Home</a>
              </div>
              <div className="success-contents">
                <div className="successsheader">
                  <h1 className="successheading">You're in the club!</h1>
                </div>
                <p className="outropara">Welcome to the Club! You are now officially a Good Karma Membership holder! Join us in our Discord below, we are excited to have you.</p>
                <div className="success_buttons">
                  <a href="https://discord.gg/8mfhgGAMJH" className="successdiscord w-button"  style={{textDecoration: "none"}}>Join Discord</a>
                  <a href={forLink} target="_blank" className="successviewnft w-button"  style={{textDecoration: "none"}}>View Your Transaction</a>
                </div>
              </div>
            </div>
          </div>)}
      {!matches && (<div></div>)}
          

      {matches && (<div></div>)}
      {!matches && ( <div style={mobilecontentOutro}>
            <div>
              <div className="back-home">
                <a href="https://goodkarmaclub.xyz" className="backhomebutton w-button"  style={{textDecoration: "none"}}>Return to Home</a>
              </div>
              <div className="success-contents">
                <div className="successsheader">
                  <h1 className="successheading">You're in the club!</h1>
                </div>
                <p className="outropara">Welcome to the Club! You are now officially a Good Karma Membership holder! Join us in our Discord below, we are excited to have you.</p>
                <div className="success_buttons">
                  <a href="https://discord.gg/8mfhgGAMJH" className="successdiscord w-button"  style={{textDecoration: "none"}}>Join Discord</a>
                  <a href={forLink} target="_blank" className="successviewnft w-button"  style={{textDecoration: "none"}}>View Your Transaction</a>
                </div>
              </div>
            </div>
          </div>)}

         
             
          <audio ref={exterior} preload="auto" loop="true" src={outsideClub1} id="outsideClubID" type="audio/mpeg">Your browser does not support the audio element.</audio>
          <audio ref={interior} preload="auto" loop="true" src={insideClub1}  id="insideClubID"  type="audio/mpeg">Your browser does not support the audio element.</audio>
           
            

    </div>             
  )
}

export default App
