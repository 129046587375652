const whiteListAddressArray = [
    "0xa5d224b43eab837aa2ee9c6ed727f1613f301a5e",
"0x9ebf693ded468fb1f6e68fa0e4e43a11d7fc7f2c",
"0x6668d39ec3404e779d62b890243902f5864119a4",
"0x8d089863b47231daccf3d3ec2aa610bf432ebce3",
"0x002932e139b805628e19af15ddbf70abd9ed2ec9",
"0xae0a1911393a3a85e87e1afd31fe6ffce2af9774",
"0x6ad71401bccc644fcdf93eac9b8b4bb919e92414",
"0x5d5e43516840c94622d7a894f94eb634bee51ad0",
"0x9e7761249995c34feb31ff4dacfdaaf1e756bc1e",
"0xa47a2ca3c27b11ace79f3bb62416efbac63c798c",
"0x2e60abd324bf4c9d0f3f18b55058ea010d3df573",
"0x8ab5496a45c92c36ec293d2681f1d3706eaff85d"
    ];
    
    export default  whiteListAddressArray 
    